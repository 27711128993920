/* Coverage:
 Statements: 100%
 Branches: 100%
 Functions: 100%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { useParams } from 'react-router';
import { useContext } from 'react'
import { StringContext } from '~/contexts/stringContext'
export default function useStrings() {
  const { translations, languages } = useContext(StringContext)
  const { lang = 'en' } = useParams()
  const t = (text: string): string => {
    return translations[lang]?.[text] || text
  }
  return {
    t,
    translations,
    languages,
  }
}
