/* Coverage:
 Statements: 36%
 Branches: 100%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { createContext } from 'react'
import type { SupportedLanguage } from '~/hooks/types'
type StringContextType = {
  translations: {
    [language: string]: {
      [text: string]: string
    }
  }
  languages: SupportedLanguage[]
}
export const StringContext = createContext<StringContextType>({
  translations: {},
  languages: [],
})
interface StringProviderProps {
  translations: StringContextType['translations']
  languages: SupportedLanguage[]
  children: React.ReactNode
}
export function StringProvider({ translations, languages, children }: StringProviderProps) {
  return (
    <StringContext.Provider
      value={{
        translations,
        languages,
      }}
    >
      {children}
    </StringContext.Provider>
  )
}
